import { Injectable } from '@angular/core';
import { Publication } from '../models';
import { Observable } from 'rxjs';
import { LjpHttpService } from './ljp-http.service';

const domainMap = [
  { domain: 'ockero', pubId: '65d32593dd8764115a422acf', useNewDesign: false, key: '' }, // test
  { domain: 'lor', pubId: '65ae6eeda958833d9390710b', useNewDesign: true, key: 'lor' }, // test
  { domain: 'hud', pubId: '621e0d6beecdff8b664710bf', useNewDesign: true, key: 'huddinge' }, // test
  { domain: 'ybladet', pubId: '65afdca5fea9720de6c5cc53', useNewDesign: true, key: 'ybladet' }, // test
  { domain: 'ttvn', pubId: '661f80ed6019567b969f1ddb', useNewDesign: true, key: 'spotlight' }, // test
  { domain: 'spotlight', pubId: '661f80ed6019567b969f1ddb', useNewDesign: true, key: 'spotlight' }, // test
  { domain: 'uppsala', pubId: '661f80cf6019567b969f1dda', useNewDesign: true, key: 'uppsala' }, // test

  { domain: 'uppsala.darub.org', pubId: '65dca5329eb941011f942f57', useNewDesign: true, key: 'uppsala' }, // test
  { domain: 'lor.darub.org', pubId: '64146f1acb9f595d8a30f73a', useNewDesign: true, key: 'lor' },
  { domain: 'lor-beta.darub.se', pubId: '64146f1acb9f595d8a30f73a', useNewDesign: true, key: 'lor' },
  { domain: 'ybladet.darub.org', pubId: '5aa8c59991fbe351437b6380', useNewDesign: true, key: 'ybladet' },
  { domain: 'ockero.darub.org', pubId: '63578ddeae6c9958cbf9cfa2', useNewDesign: false, key: '' },
  { domain: 'huddinge.darub.org', pubId: '589063b0683b66841b210b71', useNewDesign: true, key: 'huddinge' },
  { domain: 'spotlight.darub.org', pubId: '576169bb0a47211331e3a147', useNewDesign: true, key: 'spotlight' },
  { domain: 'ttvn.darub.org', pubId: '576169bb0a47211331e3a147', useNewDesign: true, key: 'ttvn' }, // test
  { domain: 'syskonbandet.darub.org', pubId: '6308c5f3c39a7b06ee7c39cd', useNewDesign: true, key: 'syskon'},
  { domain: 'insyn.darub.org', pubId: '576169c50a47211331e3a148', useNewDesign: true, key: 'insyn'},
  { domain: 'rfn.ljudportalen.se', pubId: '673d9495f574c17f7a68866b', useNewDesign: true, key: 'rfn'},
  { domain: 'ledarhunden.ljudportalen.se', pubId: '67f8b3e98ceaf5023e263d2f', useNewDesign: true, key: 'ledarhunden'},

  
  { domain: 'ybladet.se', pubId: '5aa8c59991fbe351437b6380', useNewDesign: true, key: 'ybladet' },
  { domain: 'radioockero.se', pubId: '63578ddeae6c9958cbf9cfa2', useNewDesign: false, key: '' },
  { domain: 'ockero-beta.darub.se', pubId: '63578ddeae6c9958cbf9cfa2', useNewDesign: false, key: '' },
  { domain: 'huddingetaltidning.se', pubId: '589063b0683b66841b210b71', useNewDesign: true, key: 'huddinge' },
  { domain: 'varthuddinge.se', pubId: '58fa1a110b4b5a2b73650d13', useNewDesign: true, key: 'huddinge' },
  { domain: 'taltidningenvasternorrland.se', pubId: '576169bb0a47211331e3a147', useNewDesign: true, key: 'spotlight' },
  { domain: 'lansochriksnytt.se', pubId: '64146f1acb9f595d8a30f73a', useNewDesign: true, key: 'lor'},
  { domain: 'www.norrbottenstaltidning.se', pubId: '576169c50a47211331e3a148', useNewDesign: true, key: 'insyn'},
];

@Injectable({
  providedIn: 'root'
})
export class PublicationService extends LjpHttpService {

  service = 'publications';
  domainMap = domainMap;

  getList(): Observable<[Publication]> {
    return super.getList(this.withBaseUrl(this.service, '/'), 'Publikationer') as Observable<[Publication]>;
  }

  getListByOrg(id: string): Observable<[Publication]> {
    return super.getList(this.withBaseUrl(this.service, '/by-customer', id), 'Publikationer') as Observable<[Publication]>;
  }

  getItem(id: string): Observable<Publication> {
    return super.getItem(this.withBaseUrl(this.service, '/', id), 'Publikation') as Observable<Publication>;
  }

  saveItem(item: Publication): Observable<Publication> {
    return super.save(this.withBaseUrl(this.service, '/', item._id), item, item.name);
  }

  async removeItem(id: string, name: string): Promise<boolean> {
    return await super.removeItem(this.withBaseUrl(this.service, '/', id), name);
  }
}
